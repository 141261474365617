import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";
import theme from "../ui/theme";

const ContributorsWrapper = styled.section`
  .container {
    margin: 0 auto;
    max-width: 1000px;
    padding: 90px 20px;
    @media all and (max-width: ${theme.breakpoints.md}) {
      padding: 40px 20px;
    }
  }
  h2 {
    font-size: 36px;
    margin: 0 0 2rem;
    &:after {
      border-bottom: solid 2px #ffc71b;
      content: "";
      display: block;
      width: 32%;
    }
    @media all and (max-width: ${theme.breakpoints.md}) {
      font-size: 25px;
      text-align: center;
      &:after {
        margin: 0 60px;
        width: inherit;
      }
    }
  }
  article {
    border: 1px solid #f4f5f7;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1);
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    padding: 40px;
    margin-bottom: 1.8rem;
    @media all and (max-width: ${theme.breakpoints.md}) {
      flex-wrap: wrap;
      margin-bottom: 1rem;
      padding: 20px;
      h3 {
        margin: 0 0 1rem;
        padding: 18px 0 0;
        text-align: center;
      }
    }
    h3 {
      padding: 0;
    }
    img {
      max-width: 200px;
      width: 100%;
    }
    div p:last-of-type {
      margin: 0;
    }
  }
  .col-left {
    @media all and (max-width: ${theme.breakpoints.md}) {
      flex-basis: 100%;
      text-align: center;
      margin-bottom: 20px;
    }
  }
  .col-right {
    font-family: "Montserrat", "Open Sans", arial, sans-serif;
    flex-basis: 77%;
    padding-left: 40px;
    @media all and (max-width: ${theme.breakpoints.md}) {
      flex-basis: 100%;
      padding-left: 0;
    }
  }
`;

const Contributors = ({ heading, contributors }) => (
  <ContributorsWrapper>
    <div className="container">
      <h2>{heading ? heading : "Contributing Writers"}</h2>
      {contributors.map((writer) => {
        return (
          <article key={writer.photo.id}>
            <div className="col-left">
              <Img
                fixed={writer.photo.localFile.childImageSharp.fixed}
                alt={writer.photo.alt_text}
              />
            </div>
            <div className="col-right">
              <h3>{writer.name}</h3>
              <div
                dangerouslySetInnerHTML={{
                  __html: writer.biography,
                }}
              />
            </div>
          </article>
        );
      })}
    </div>
  </ContributorsWrapper>
);

export default Contributors;

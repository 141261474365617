import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import Contributors from "../components/Contributors";
import BackgroundImage from "gatsby-background-image";
import SocialLinks from "../components/SocialLinks";
import { FaHeart } from "react-icons/fa";
import theme from "../ui/theme";

const StyledBackgroundImage = styled(BackgroundImage)`
  margin-bottom: 60px;
  padding: 80px 10px;
  text-align: center;
  @media all and (max-width: ${theme.breakpoints.md}) {
    margin-bottom: 30px;
    padding: 30px 10px;
  }
  h1 {
    font-size: 45px;
    margin-bottom: 10px;
    @media all and (max-width: ${theme.breakpoints.md}) {
      font-size: 30px;
    }
  }
  h3 {
    font-size: 20px;
  }
  svg {
    fill: #1b7dff;
    font-size: 24px;
    vertical-align: bottom;
  }
`;

const AboutContainer = styled.div`
  margin: 0 auto;
  text-align: center;
  max-width: 800px;
  padding: 0 20px;
  p {
    margin-bottom: 40px;
    @media all and (max-width: ${theme.breakpoints.md}) {
      margin-bottom: 30px;
    }
  }
  .social-links {
    font-size: 30px;
    a {
      color: #081a31;
      margin: 0 12px;
    }
  }
`;

const About = ({ data }) => (
  <Layout templateName="wide about">
    <Seo
      title={data.wordpressPage.yoast_meta.yoast_wpseo_title}
      description={data.wordpressPage.yoast_meta.yoast_wpseo_metadesc}
      canonical={data.wordpressPage.yoast_meta.yoast_wpseo_canonical}
      datePublished={data.wordpressPage.date}
      dateModified={data.wordpressPage.modified}
      schemaType="WebPage"
      image={data.headerImage.childImageSharp.src}
    />
    <StyledBackgroundImage fluid={data.headerImage.childImageSharp.fluid}>
      <h1>{data.wordpressPage.title}.</h1>
      <h3>
        We <FaHeart /> Travel Nurses.
      </h3>
    </StyledBackgroundImage>
    <AboutContainer>
      <p>
        Travel nurses pay a vital role in healthcare today by filling staffing
        needs so facilities can continue providing excellent patient care.
        Travelnursing.org connects the top staffing agencies in the country with
        highly skilled nurses to help fill those needs. Our philosophy is
        simple: Everyone wins — nurses, hospitals, and patients — with travel
        nursing.
      </p>
      <SocialLinks />
    </AboutContainer>
    {data.wordpressPage.acf && (
      <Contributors contributors={data.wordpressPage.acf.contributors} />
    )}
  </Layout>
);

export default About;

export const query = graphql`
  query AboutPageQuery {
    headerImage: file(relativePath: { eq: "bg-airplane-skyline.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    wordpressPage(wordpress_id: { eq: 8 }) {
      title
      date
      modified
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
        yoast_wpseo_canonical
      }
      acf {
        contributors {
          name
          biography
          photo {
            alt_text
            id
            localFile {
              childImageSharp {
                fixed(width: 200, height: 200) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
  }
`;
